<template>
  <div>
    <div class="checkout-card hidden-mobile">
      <button class="lab-btn lab-btn-back" @click="$router.push('/dashboard')">
        <feather-icon icon="ArrowLeftIcon" size="20"/>
        Back To Dashboard
      </button>

      <div class="select-service-container">
        <div>
          <div class="select-service-title">
            <h4>What do you want to do?</h4>
          </div>
          <div class="select-service-item"  @change="onChangeProduct(PRODUCT_CODES.UPLOAD_G25_RAW_DATA)"
            :class="{'selected': selectedProductCode == PRODUCT_CODES.UPLOAD_G25_RAW_DATA}">
            <label class="lab-radio-container">
              Upload Raw DNA Data
              <input type="radio" :checked="selectedProductCode == PRODUCT_CODES.UPLOAD_G25_RAW_DATA ? 'checked' : ''" name="radio">
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="select-service-item"  @change="onChangeProduct(PRODUCT_CODES.UPLOAD_G25_COORDINATES)"
            :class="{'selected': selectedProductCode == PRODUCT_CODES.UPLOAD_G25_COORDINATES}">
            <label class="lab-radio-container">
              Upload G25 Coordinates
              <input type="radio" :checked="selectedProductCode == PRODUCT_CODES.UPLOAD_G25_COORDINATES ? 'checked' : ''" name="radio">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="step-buttons">
        <button class="lab-btn back" @click="$router.back()">Back</button>
        <button class="lab-btn next" :class="{'valid': selectedProductCode != null }" 
          @click="onRouting(selectedProductCode)">
          Next
        </button>
      </div>
    </div>
    <div class="checkout-card-mobile hidden-desktop">
      <button class="lab-btn lab-btn-back" @click="$router.push('/dashboard')">Back To Dashboard</button>
      <div class="select-service-container-mobile">
        <div class="mt-2">
          <div class="select-service-title">
            <h4>What do you want to do?</h4>
          </div>
          <div class="select-service-item raw-data mt-2"  @click="onRouteChangeProduct(PRODUCT_CODES.UPLOAD_G25_RAW_DATA)">
            Upload Raw DNA Data
          </div>
          <div class="select-service-item coordinate"  @click="onRouteChangeProduct(PRODUCT_CODES.UPLOAD_G25_COORDINATES)">
            Upload G25 Coordinates
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PRODUCT_CODES, G25_CHECKOUT_STEPS } from '@/common/config';

export default {
  components: {
  },
  data() {
    return {
      PRODUCT_CODES,
      G25_CHECKOUT_STEPS,
    }
  },
  methods: {
    onChangeProduct(itemCode) {
      this.$store.state.deepACheckout.selectedProductCode = itemCode;
      this.$store.state.deepACheckout.selectedStepCode = G25_CHECKOUT_STEPS.UPLOAD;
    },
    onRouteChangeProduct(itemCode) {
      this.$store.state.deepACheckout.selectedProductCode = itemCode;
      this.$store.state.deepACheckout.selectedStepCode = G25_CHECKOUT_STEPS.UPLOAD;

      this.onRouting(itemCode);
    },

    onRouting(itemCode) {
      this.$router.push(`/checkout/g25/${G25_CHECKOUT_STEPS.UPLOAD}/${itemCode}`);
    },
  },
  computed: {
    selectedProductCode() {
      return this.$store.state.deepACheckout.selectedProductCode;
    },
  },
}
</script>

<style lang="scss" scoped>

.select-service-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  .select-service-title {
    margin-bottom: 2rem;
  }

  .select-service-item {
    padding: 1rem 1.5rem;
    margin-bottom: 5px;
  }
  .select-service-item:hover {
    background-color: var(--lab-light-house);
    border-radius: 10px;
  }
  .select-service-item.selected {
    background-color: var(--lab-light-house);
    border-radius: 10px;
  }
}

.select-service-container-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  .select-service-item {
    font-size: 1rem;
    color: var(--lab-white);
    padding: 1rem 1.5rem;
    text-align: center;
    background-color: red;
    margin-top: 10px;
    border-radius: 0.4rem;
  }

  .raw-data {
    background-color: var(--lab-lago-blue-color);
  }
  .coordinate {
    background-color: var(--lab-black);
  }
}

</style>

<template>
  <div>
    <b-alert variant="success" v-if="checkoutDataResponse.statusCode !== 200" show>
      <div class="alert-body">
        <span>{{checkoutDataResponse.message}}</span>
        <a v-if="checkoutDataResponse.statusCode === 1008" 
          @click="$router.push({name: 'login'})" class="lab-text-sunrise"> 
          Click Here For Login
        </a>
      </div>
    </b-alert>

    <div class="" :class="{'checkout-card': screen === 'DESKTOP', 'checkout-card-mobile': screen === 'MOBILE'}">
        <div class="title">
          <h4>Register</h4>
        </div>

        <validation-observer ref="accountValidation">
          <b-form class="mt-2"  @submit.prevent>
            <div class="mobile-body">
              <b-row>
                <!-- first name -->
                <b-col cols="12">
                  <b-form-group
                    label="Name"
                    label-for="firstName"
                  >
                    <validation-provider #default="{ errors }" rules="required" name="Name">
                      <b-input-group 
                        class="input-group-merge" 
                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                        <b-form-input
                          id="firstName"
                          v-model="firstName"
                          placeholder="Name"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Surename"
                    label-for="lastName"
                  >
                    <validation-provider #default="{ errors }" rules="required" name="lastName">
                      <b-input-group 
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                        <b-form-input
                          id="lastName"
                          v-model="lastName"
                          placeholder="Surename"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- email -->
              <b-row class="mt-1">
                <b-col cols="12" md="12" xl="12">
                  <b-form-group
                    label="Email"
                    label-for="userEmail"
                  >
                    <validation-provider #default="{ errors }" rules="required|email" name="email">
                      <b-input-group 
                        class="input-group-merge" 
                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                          <b-form-input
                            id="userEmail"
                            type="email"
                            v-model="userEmail"
                            placeholder="Email"
                            autocomplete="off"
                            :state="errors.length > 0 ? false:null"
                          />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              
              <!-- password -->
              <b-row class="mt-1">
                <b-col cols="12" md="12" xl="12">
                  <b-form-group
                    label="Password"
                    label-for="password"
                  >
                    <validation-provider #default="{ errors }" rules="required" name="password">
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="password"
                          :type="passwordFieldType"
                          v-model="password"
                          placeholder="Password"
                          autocomplete="off"
                          :state="errors.length > 0 ? false:null"
                        />
                        <b-input-group-append is-text :class="{ 'error-form-btn': errors.length > 0 }">
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="step-buttons-mobile hidden-desktop">
              <button class="lab-btn back" type="button" @click="prev()">Back</button>
              <button class="lab-btn next valid" type="submit" @click="onSubmit()" :disabled="isStartLoading">
                <b-spinner v-if="$store.getters.getLoader || isStartLoading" small/>
                Next
              </button>
            </div>
            <div class="step-buttons  hidden-mobile">
              <button class="lab-btn back" @click="prev()">Back</button>
              <button class="lab-btn next valid" @click="onSubmit()" :disabled="isStartLoading">
                <b-spinner v-if="$store.getters.getLoader || isStartLoading" small/>
                Next
              </button>
            </div>
          </b-form>
        </validation-observer>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { G25_CHECKOUT_STEPS } from '@/common/config';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms';

import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: `deepACheckout/getUserDataField`,
  mutationType: `deepACheckout/updateUserDataField`,
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    screen: {
      type: String,
      default: 'DESKTOP',
    },
    checkoutModule: {
      type: String,
      default: 'deepACheckout',
    },
    currentRouteData: {
      type: Object,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,

      checkoutDataResponse: { statusCode: 200 },
      isStartLoading: false,
    }
  },
  methods: {
    onSubmit() {
      this.$refs.accountValidation.validate().then(success => {
        if (success) {
          if (this.isStartLoading === true) {
            return;
          }
          this.isStartLoading = true;
      
          const signUpData = this.$store.getters['deepACheckout/getUserData'];
          this.$store.dispatch('account/signUp', signUpData)
            .then(response => {
              if (response.statusCode === 200) {
                useJwt.login({
                  userNameOrEmail: signUpData.userEmail,
                  password: signUpData.password,
                  rememberMe: true,
                })
                  .then(loginResponse => {
                    const userData = loginResponse.result
                    useJwt.setToken(loginResponse.result.token)
                    useJwt.setRefreshToken(loginResponse.result.token)
                    useJwt.setUserData(userData)
                    this.$store.commit('account/setIsLoggedIn', true);
                    
                    this.isStartLoading = false;
                    this.$router.push(`/checkout/g25/${G25_CHECKOUT_STEPS.CONFIRM}/${this.currentRouteData.productCode}`);
                  });
              } else {
                this.isStartLoading = false;
                this.checkoutDataResponse = response;
              }
            });
        }
      });
    },
    prev() {
      this.$router.back();
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...mapFields([
      'userEmail',
      'firstName',
      'lastName',
      'password',
    ]),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.checkout-account {
  padding: 1rem 1.5rem;
  .form-control:focus {
    font-size: 0.8rem;
    border: 2px solid var(--lab-minted-elegance-color);
  }
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .text-danger {
    font-size: 0.7rem;
  }
}

.checkout-card-mobile {
  .mobile-body {
    padding: 1rem 2rem;
    min-height: 400px;
  }
  .title {
    text-align: center;
  }
}
</style>

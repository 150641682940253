<template>
  <div>
    <alert :message="warningMessage" variant="warning"/>
    
    <div class="payment-container">
      <div class="cs-payment-card mb-1" v-show="paymentStatus.isComplete === false">
        <h4>Payment</h4>
        <h4>Cost: <span class="cost">€{{subtotal}}</span></h4>
      </div>

      <div id="payment-request-button">
      </div>
      <form id="payment-form" @submit.prevent="handleSubmit">
        <div id="payment-element" />
        <button
          id="submit"
          v-show="false"
        >
          Pay now
        </button>
        <div v-show="paymentStatus.isComplete">
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="StarIcon"
              />
              <span class="ml-25">{{paymentStatus.message}}</span>
            </div>
          </b-alert>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Ripple from 'vue-ripple-directive'

export default {
  props: {
    onClickFinish: {
      type: Function,
      default() {
        return {}
      },
    },
    checkoutModule: {
      type: String,
      default: 'deepACheckout',
    },    
    currentRouteData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stripeAPIToken: 'pk_live_51JShLKDcr8M4ba7V6MfiWHQWBLjrYJFPIf0Hae8GVS96IrLPcTkwpTTDTYmLor69KmLT1PoTfmFhKCGhpF0cQQb000dzANwogq',
      // stripeAPIToken: 'pk_test_51JShLKDcr8M4ba7VlnhOxxU3IPxsBkjfSWFxONvbYPpmKrQ5zJL0V7LV6ybQAptDWqXoHDV1XFnZvMpFFORWL9EP00hsOXv2cR',
      stripe: null,
      elements: '',
      paymentElement: '',
      card: '',
      clientSecret: null,
      paymentStatus: {
        isComplete: false,
        message: '',
      },
      paymentElementOptions: {
        defaultValues: {
          billingDetails: {
            email: 'user@example.com'
          }
        }
      },
      isContinuePaying: false,
      canPayWithWallet: false,

      warningMessage: null,
    }
  },
  methods: {
    includeStripe( URL, callback ){
      let documentTag = document, tag = 'script',
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },

    configureStripe(){
      this.stripe = Stripe(this.stripeAPIToken);

      this.createPaymentIntent(() => {
        this.elements = this.stripe.elements({clientSecret: this.clientSecret});

        this.paymentElement = this.elements.create('payment', this.paymentElementOptions);
        this.paymentElement.mount('#payment-element');
      });
    },

    createPaymentIntent(callback) {
      this.warningMessage = null;

      const orderData = this.$store.getters[`${this.checkoutModule}/getCreateOrderData`];
      const subTotal = this.$store.getters[`${this.checkoutModule}/getOrderSubTotal`];
      if (subTotal === "0") {
        return;
      }
      const paymentQuery = {
        currency: 'EUR',
        paymentAmount: subTotal.toString().replace(',', '.'),
        promotionDetailGuid: orderData.promotionDetailGuid,
      };
      this.$store.dispatch(`${this.checkoutModule}/createPaymentIntent`, paymentQuery)
        .then(response => {
          if (response.statusCode === 200) {
            this.clientSecret = response.result.clientSecret;
            this.$store.commit(`${this.checkoutModule}/setCreateOrderData`, { key: 'paymentGuid', value: response.result.paymentGuid });
            callback();
          } else {
            this.warningMessage = response.message;
          }
        });
    },

    handleSubmit(callback) {
      this.warningMessage = null;
      const returnUrl = this.checkoutModule === 'deepACheckout' ? 
                    `${window.location.origin}/checkout/g25/confirm/${this.currentRouteData.productCode}` :
                    `${window.location.origin}/checkout/trait/confirm/${this.currentRouteData.productCode}`;

      return this.stripe
              .confirmPayment({
                elements: this.elements,
                confirmParams: {
                  return_url: returnUrl,
                },
                redirect: 'if_required'
              }).then(function(result) {
                if (typeof callback === 'function') {
                  callback(result);
                }
                this.isContinuePaying = false;
              }.bind(this));
    },
  },
  computed: {
    subtotal() {
      const _subTotal = this.$store.getters[`${this.checkoutModule}/getOrderSubTotal`];
      return _subTotal;
    },
  },
  mounted(){
    this.includeStripe('js.stripe.com/v3/', function(){
      this.configureStripe();
      // this.payWithGoogleWallet();
    }.bind(this));
  },
}
</script>

<style lang="scss">
  .StripeElement--complete {
    display: none !important;
  }
</style>
<style lang="scss" scoped>
.payment-container {
  padding: 0.2rem 1.5rem;

  .cs-payment-card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cost {
      color:  var(--lab-minted-elegance-color);
    }
  }
}
</style>

<template>
  <div>
    <div class="checkout-container" ref="checkout_container" v-if="innerWidth > 769">
      <div class="checkout-body" ref="checkout_body">
        <select-service v-if="currentRouteData.step === 'select-service'"/>
        <upload-raw-file v-if="currentRouteData.step === 'upload' && currentRouteData.productCode === PRODUCT_CODES.UPLOAD_G25_RAW_DATA"/>
        <upload-coordinate v-if="currentRouteData.step === 'upload' && currentRouteData.productCode === PRODUCT_CODES.UPLOAD_G25_COORDINATES"/>
        <account v-if="currentRouteData.step === 'account' && isUserLoggedIn === false" :currentRouteData="currentRouteData"/>
        <confirm v-if="currentRouteData.step === 'confirm'" :currentRouteData="currentRouteData"/>
      </div>
    </div>
    <div class="checkout-container-mobile"  v-if="innerWidth <= 769">
      <select-service v-if="currentRouteData.step === 'select-service'"/>
      <upload-raw-file screen="MOBILE" v-if="currentRouteData.step === 'upload' && currentRouteData.productCode === PRODUCT_CODES.UPLOAD_G25_RAW_DATA"/>
      <upload-coordinate screen="MOBILE" v-if="currentRouteData.step === 'upload' && currentRouteData.productCode === PRODUCT_CODES.UPLOAD_G25_COORDINATES"/>
      <account screen="MOBILE" v-if="currentRouteData.step === 'account' && isUserLoggedIn === false" :currentRouteData="currentRouteData"/>
      <confirm screen="MOBILE" v-if="currentRouteData.step === 'confirm'" :currentRouteData="currentRouteData"/>
    </div>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service';
import { PRODUCT_CODES, G25_CHECKOUT_STEPS } from '@/common/config';

import SelectService from './steps/SelectService.vue';
import UploadRawFile from './steps/UploadRawFile.vue';
import UploadCoordinate from './steps/UploadCoordinate.vue';
import Confirm from './steps/Confirm.vue';
import Account from '../steps/Account.vue';

export default {
  components: {
    SelectService,
    UploadRawFile,
    UploadCoordinate,
    Confirm,
    Account,
  },
  data() {
    return {
      PRODUCT_CODES,
      G25_CHECKOUT_STEPS,

      currentRouteData: { step: '', productCode: '' },
      selectedProduct: null,

      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    }
  },
  methods: {
    onSetCurrentRouteData() {
      this.currentRouteData.step = this.$router.currentRoute.params.step;
      if (this.$router.currentRoute.params.product_code) {
        this.currentRouteData.productCode = this.$router.currentRoute.params.product_code;
      }
    },
    onLoadProduct() {
      /* eslint-disable */
      if (this.currentRouteData.step === G25_CHECKOUT_STEPS.UPLOAD) {
        this.$store.dispatch('products/fetchProduct', this.currentRouteData.productCode)
          .then(response => {
            if (response.statusCode === 200) {
              response.result.productPrices.forEach(element => {
                element.label = response.result.product.productType === 1 ? `${element.productCount}` : `${element.productCount}`;
              });
              
              this.$store.commit('deepACheckout/setProductData', response.result);
            } else {
              AlertService.error(this, response.message)
            }
          });
      }
    },

    adjustHeight() {
      const container = this.$refs.checkout_container;
      const bodyHeight = container.querySelector('.checkout-body').clientHeight;
      container.style.height = bodyHeight > window.innerHeight ? bodyHeight + 50 + 'px' : window.innerHeight + 'px';
    },

    handleBeforeUnload(event) {
      this.$store.state.deepACheckout.createOrderData.kitList.forEach(item => {
        item.isLoading = false;
      });
      
      localStorage.setItem('deepACheckout', JSON.stringify(this.$store.state.deepACheckout));
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
  },
  mounted() {
    if (this.innerWidth > 769) {
      const observer = new MutationObserver(this.adjustHeight);
      observer.observe(this.$refs.checkout_body, { attributes: true, childList: true, subtree: true });
    }
    
    this.onSetCurrentRouteData();
    this.onLoadProduct();

    const isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? "pagehide" : "beforeunload";
    window.addEventListener(eventName, this.handleBeforeUnload);
  },  
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);

    const isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? "pagehide" : "beforeunload";
    window.removeEventListener(eventName, this.handleBeforeUnload);
  },
  watch: {    
    $route(to, from) {
      this.onSetCurrentRouteData();
      this.onLoadProduct();
    },
  },
}
</script>

<style lang="scss">
@import "../checkout.scss";
</style>

<style lang="scss" scoped>
.checkout-container {
  padding: 2rem;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.checkout-container-mobile {
  background-color: #fff;
  height: 100%;
  padding: 1rem;
}
</style>

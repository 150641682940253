<template>
  <div>
    <div class="checkout-card hidden-mobile">
      <div class="upload-coordinate-container">
        <div class="d-flex justify-content-center align-items-center mr-3">
          <upload-coordinate-icon />
        </div>
        <div>
          <div class="title">
            <h4>Upload your coordinates</h4>
          </div>

          <div class="upload-coordinate-body">
            <div class="desc">
              <p>If you previously own G25 coordinates, you can upload them to use the app. Upload only the <b>SCALED</b> coordinates.</p>
            </div>

            <div class="select-quantity">
              <h6>How many coordinates (per individiual) do you want to upload?</h6>
              <quantity-dropdown />
              <p>
                Upload your Raw DNA files for each field below and then give them a recognazible name i.e. ‘Mother’s DNA’
              </p>
            </div>

            <div class="kits mb-4">
              <div class="kit-item mb-2" 
                v-for="(kitItem, index) in orderKits"
                :key="index">

                <h6>{{index + 1}}.Kit</h6>
                <div class="kit-item-body">
                  <div class="d-flex justify-content-start align-items-center">
                    <input type="text" class="form-control kit-name mr-1" v-model="kitItem.kitName"
                      placeholder="Kit name"
                      :disabled="kitItem.targetCoordinate === ''"
                      :class="{ 
                        'dashed-input': kitItem.emptyKitName,
                        'filled-input': kitItem.kitName.length >= 1,
                      }"/>

                    <button class="lab-btn past-coordinates" @click="openCoordinateModal(kitItem)"> <past-icon /> Past Coordinates </button>
                    
                    <!-- <span class="status ml-1" v-show="kitItem.targetCoordinate === ''"> 
                      <progress-icon /> 0 %
                    </span> -->
                    <span class="status ml-1" v-show="kitItem.targetCoordinate !== ''"> 
                      <checked-icon /> Completed
                    </span>
                  </div>
                  <!-- <div class="d-flex justify-content-start align-items-center">
                    <span class="delete-kit">
                      <feather-icon
                        @click="deleteOrder(data.item.id)"
                        icon="Trash2Icon"
                        class="cursor-pointer white"
                        size="16"
                      />
                    </span>
                  </div> -->
                </div>
                <small class="text-danger" v-show="kitItem.emptyTargetCoordinate">
                  You must paste coordinate
                </small>
              </div>
            </div>

            <div class="warn-cost">
              <p>Having a problem with G25 coordinates? <a href="https://illustrativedna.com/contact/" target="_blank">Contact support</a> </p>
              <hr />
              <div class="cost">Cost: <span>{{ productData != null? productData.product.currencyUnit : ''}}{{subtotal}} </span></div>
            </div>
          </div>
        </div>

      </div>
      <div class="step-buttons">
        <button class="lab-btn back" @click="prev()">Back</button>
        <button class="lab-btn next valid" @click="submit()">
          Next
        </button>
      </div>
    </div>

    <div class="checkout-card-mobile hidden-desktop">
      <div class="upload-coordinate-container">
        <div class="title">
          <h4>Upload your coordinates</h4>
        </div>

        <div class="upload-coordinate-body">
          <div class="select-quantity">
            <h6>How many coordinates (per individiual) do you want to upload?</h6>
            <div class="d-flex justify-content-center">
              <quantity-dropdown />
            </div>
            <p>
              Upload your Raw DNA files for each field below and then give them a recognazible name i.e. ‘Mother’s DNA’
            </p>
          </div>

          <div class="kits kits-mobile mb-4 mobile-body">
            <div class="kit-item mb-2" 
              v-for="(kitItem, index) in orderKits"
              :key="index">

              <h6>
                {{index + 1}}.Kit
              </h6>

              <div class="kit-item-body">
                <div class="d-flex justify-content-start align-items-center">
                  <input type="text" class="form-control kit-name mr-1" v-model="kitItem.kitName"
                    placeholder="Kit name"
                    :disabled="kitItem.targetCoordinate === ''"
                    :class="{ 
                      'dashed-input': kitItem.emptyKitName,
                      'filled-input': kitItem.kitName.length >= 1,
                    }"/>

                  <button class="lab-btn past-coordinates" @click="openCoordinateModal(kitItem)"> <past-icon /> Past Coordinates </button>
                  
                  <span class="status ml-1" v-show="kitItem.targetCoordinate !== ''"> 
                    <checked-icon />
                  </span>
                  
                </div>
                <!-- <div class="d-flex justify-content-start align-items-center">
                  <span class="delete-kit">
                    <feather-icon
                      @click="deleteOrder(data.item.id)"
                      icon="Trash2Icon"
                      class="cursor-pointer white"
                      size="16"
                    />
                  </span>
                </div> -->
              </div>
              <small class="text-danger" v-show="kitItem.emptyTargetCoordinate">
                You must paste coordinate
              </small>
            </div>
          </div>
        </div>
        <div class="warn-cost">
          <p>Having a problem with G25 coordinates? <a href="https://illustrativedna.com/contact/" target="_blank">Contact support</a> </p>
          <hr />
          <div class="cost">Cost: <span>{{ productData != null? productData.product.currencyUnit : ''}}{{subtotal}} </span></div>
        </div>
        <div class="step-buttons-mobile">
          <button class="lab-btn back" @click="prev()">Back</button>
          <button class="lab-btn next valid" @click="submit()">
            Next
          </button>
        </div>
      </div>
    </div>
  
    <PastCoordinateModal v-if="modalObject.showModal" :modalObject="modalObject"/>
  </div>
</template>

<script>
import { PRODUCT_CODES, G25_CHECKOUT_STEPS } from '@/common/config';
import UploadCoordinateIcon from '@/layouts/icons/store/UploadCoordinateIcon.vue';
import PastIcon from '@/layouts/icons/PastIcon.vue';
import CheckedIcon from '@/layouts/icons/CheckedIcon.vue';
import QuantityDropdown from './others/QuantityDropdown.vue';
import PastCoordinateModal from './others/PastCoordinateModal.vue'

export default {
  components: {
    UploadCoordinateIcon,
    PastIcon,
    CheckedIcon,
    QuantityDropdown,
    PastCoordinateModal,
  },
  props: {
    screen: {
      type: String,
      default: 'DESKTOP',
    },
  },
  data() {
    return {
      PRODUCT_CODES,
      G25_CHECKOUT_STEPS,

      modalObject: {
        showModal: false,
        item: null,
      },
    }
  },
  methods: {
    onChangeProduct(itemCode) {
      this.selectedProductCode = itemCode;
    },
    openCoordinateModal(fileItem) {
      this.modalObject.item = fileItem;
      this.modalObject.showModal = true;
    },

    submit() {
      /* eslint-disable */
      const kitsData = this.$store.getters['deepACheckout/getCreateOrderData'].kitList;

      let isEmptyCoordinate = false;
      kitsData.forEach(item => {
        if (item.targetCoordinate === '') {
          isEmptyCoordinate = true;
          item.emptyTargetCoordinate = true;
        } else {
          item.emptyTargetCoordinate = false;
        }
      });

      if (isEmptyCoordinate) {
        return;
      }
      
      let isemptyKitName = false;
      kitsData.forEach(item => {
        if (item.kitName === '') {
          isemptyKitName = true;
          item.emptyKitName = true;
        } else {
          item.emptyKitName = false;
        }
      });

      if (isemptyKitName) {
        return;
      }

      this.next();
    },

    next() {
      if (this.isUserLoggedIn) {
        this.$router.push(`/checkout/g25/${G25_CHECKOUT_STEPS.CONFIRM}/${PRODUCT_CODES.UPLOAD_G25_COORDINATES}`);
      } else {
        this.$router.push(`/checkout/g25/${G25_CHECKOUT_STEPS.ACCOUNT}/${PRODUCT_CODES.UPLOAD_G25_COORDINATES}`);
      }
    },
    prev() {
      this.$router.push(`/checkout/g25/${G25_CHECKOUT_STEPS.SELECT_SERVICE}`);
    },
  },
  computed: {
    productData() {
      return this.$store.getters['deepACheckout/getProductData'];
    },
    orderKits() {
      return this.$store.getters['deepACheckout/getCreateOrderData'].kitList;
    },
    subtotal() {
      return this.$store.getters['deepACheckout/getCreateOrderData'].subtotal;
    },
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
  },
}
</script>

<style lang="scss" scoped>

.upload-coordinate-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  height: 90%;

  .upload-coordinate-body {
    position: relative;
  }

  .title {
    margin-top: 10px;
    margin-bottom: 2rem;
    h4 {
      font-size: 1.6rem;
      font-weight: bold;
      color: var(--lab-carbon-fiber);
    }
  }
  .desc {
    margin-top: 20px;
    p {
      line-height: 1rem;
      font-size: 0.7rem;
      color: var(--lab-hydrargyrum);
    }
  }

  .select-quantity {
    h6 {
      font-size: 0.7rem;
      font-weight: 600;
    }
    p {
      margin-top: 20px;
      line-height: 1rem;
      font-size: 0.7rem;
      font-weight: bold;
      color: var(--lab-carbon-fiber);
    }
  }
}

.checkout-card-mobile {
  .mobile-body {
    min-height: 220px;
  }
  .title {
    text-align: center;
  }
  .select-quantity {
    h6 {
      font-size: 0.8rem;
    }
    p {
      font-size: 0.8rem;
      color: var(--lab-hydrargyrum);
    }
  }
  .warn-cost {
    p {
      font-size: 0.8rem;
      line-height: 1.2rem;
      color: var(--lab-carbon-fiber);
    }
  }
}

.form-control {
  border: 2px solid var(--lab-light-house) !important;
  background-color: var(--lab-white) !important;
  color: #595959 !important;
}
.form-control:disabled {
  background-color: var(--lab-light-house) !important;
}
.form-control:not(:empty) {
  background-color: var(--lab-white) !important;
}
.form-control:focus {
  background-color: var(--lab-white) !important;
}

@media screen and (max-width: 769px) {
  .upload-coordinate-container {
    display: block;
    height: 100%;
  }
}

</style>
